import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-container settings-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_vue3_markdown_it = _resolveComponent("vue3-markdown-it")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    returnRoute: $setup.returnRoute,
    shouldShowShareIcon: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_icon, { src: $setup.contentImage }, null, 8, ["src"]),
        _createVNode(_component_vue3_markdown_it, {
          source: $setup.contentText,
          breaks: ""
        }, null, 8, ["source"])
      ])
    ]),
    _: 1
  }, 8, ["returnRoute"]))
}