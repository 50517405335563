
import {ref, computed} from "vue";
import { useStore } from "vuex";
import { chevronBack } from "ionicons/icons";
import translate from "@/views/translate";
import {getImageDataUri} from "@/utilities/Compatibility";

export default {
    name: "Privacy",
    components: {},
    async ionViewWillEnter() {
        const currentView = this as any;
        currentView.returnRoute = currentView.$store.state.prevPage;
        currentView.$store.dispatch("setHeaderImage", getImageDataUri(currentView.t("messages.privacyPage.headerImage")));
        currentView.$store.dispatch("setHeaderTitle", "");
    },
    setup() {
        const { t } = translate();
        const store = useStore();
        const returnRoute = ref("" as string);
        return {
            t,
            store,
            returnRoute,
            contentImage: computed(() => getImageDataUri(t("messages.privacyPage.contentImage"))),
            contentText: computed(() => t("messages.privacyPage.contentText")),
            chevronBack,
        };
    },
};
